import React, { useEffect, useRef, useState } from "react";
import {
    Form,
    Transition,
    Segment,
    Pagination,
    Label,
    Icon,
    Popup,
} from "semantic-ui-react";
import { SearchBar, VALIDATION_PATTERNS } from "dyl-components";
import "./index.scss";

const SearchableOptions = ({
    loading,
    search,
    searchFunction,
    cancelSearchFunction,
    searchPlaceholder,
    onChangeSearch,
    searchSubcomponent,
    isSearchResultsVisible,
    renderSearchResults,
    isPaginationVisible,
    paginationProps,
    validationPattern = VALIDATION_PATTERNS.alphanumeric_search,
    values,
    label,
    onChangeSelected,
    floatingResults = false,
}) => {

    const searchBarRef = useRef(null);
    const [searchBarWidth, setSearchBarWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (searchBarRef?.current?.inputRef?.current) {
                //Get the actual HTML DOM input element, and its parent div
                const inputElement = searchBarRef.current.inputRef.current;
                const parentDiv = inputElement.parentNode;
    
                //Find the X button inside the div and get its width
                let buttonWidth = 0;
                if (parentDiv?.tagName === "DIV") {
                    const children = Array.from(parentDiv.children) || [];
                    buttonWidth = children?.find((element) => element.tagName === "BUTTON")?.offsetWidth || 0;
                }
    
                const width = inputElement.offsetWidth + buttonWidth;
                setSearchBarWidth(width);
            }
        }

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        })

        if (searchBarRef?.current?.inputRef?.current) {
            const parentDiv = searchBarRef.current.inputRef.current.parentNode;
            resizeObserver.observe(parentDiv);
        }

        return () => {
            resizeObserver.disconnect();
        }
    }, [])

    return (
        <React.Fragment>
            <b>{label}</b>
            {(values && values.length && (
                <div className="ToggableDropdown_Options">
                    {values.map((value, idx) => {
                        return (
                            <Label
                                key={idx}
                                className="ToggableDropdown__label"
                            >
                                {value.text || ""}
                                <Icon
                                    style={{ marginLeft: "5px" }}
                                    className="fas fa-times"
                                    link
                                    onClick={(e) => {
                                        onChangeSelected(e, {value});
                                    }}
                                />
                            </Label>
                        );
                    })}
                </div>
            )) ||
                ""}

            <Popup 
                className="SearchableOptions__resultsPopup"
                trigger={(
                    <Form.Group>
                        <Form.Field>
                            <SearchBar 
                                isSearching={loading}
                                search={search}
                                searchFcn={searchFunction}
                                cancelFcn={cancelSearchFunction}
                                placeholder={searchPlaceholder}
                                onChange={onChangeSearch}
                                validationPattern={validationPattern}
                                ref={searchBarRef}
                            />
                        </Form.Field>
                        {searchSubcomponent}
                    </Form.Group>
                )}
                content={(
                    <div 
                        style={{
                            ...(searchBarWidth ? {width: searchBarWidth} : {})
                        }}
                    >
                        {renderSearchResults}
                        {isPaginationVisible && (
                            <Segment className="SeachableOptions__floatingPagination" textAlign="right">
                                <Pagination {...paginationProps} />
                            </Segment>
                        )}
                    </div>
                )}
                open={floatingResults && isSearchResultsVisible}
                position="bottom"
                basic
                flowing
            />
            
            {!floatingResults && (
                <Transition visible={isSearchResultsVisible}>
                    <Segment.Group>
                        {renderSearchResults}
                        {isPaginationVisible && (
                            <Segment textAlign="right">
                                <Pagination {...paginationProps} />
                            </Segment>
                        )}
                    </Segment.Group>
                </Transition>
            )}
        </React.Fragment>
    );
};

export default SearchableOptions;
