import React from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import "./index.scss"
import ButtonLink from 'dyl-components/atoms/ButtonLink';

const STAGE_ICONS = {
    opportunity: "fa-solid fa-funnel-dollar",
    lead: "fa-solid fa-id-card",
}

const OfficeViewMiddleHeader = ({ person, onRemovePerson, isDialDisabled }) => {
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Avatar
                    size='5em'
                    round
                    maxInitials={2}
                    name={person.name}
                    style={{ marginRight: 15 }}
                />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div style={{width: 'fit-content'}}>
                                <Link to={person.contact_id ? `/contact/${person.contact_id}` : `/account/${person.account_id}`} target='_blank' rel='noopener noreferrer'>
                                    <Header as={"h1"} className='OfficeView__headerName'>
                                        {person.name}
                                    </Header>
                                </Link>
                            </div>
                        </div>
                        <ButtonLink onClick={onRemovePerson}>
                            <Icon name='x' size='big' className='OfficeView__closeHeaderIcon' />
                        </ButtonLink>
                    </div>
                    <div style={{ display: 'flex', gap: 15 }}>
                        <div>
                            <Icon
                                className={`fa-solid ${person.contact_id ?
                                    ("fa-user") :
                                    (person.account_details?.account_type === "business" ? "fa-buildings" : "fa-house-chimney")
                                }`}
                                style={{ marginRight: 6 }} 
                            />
                            <strong>{person.contact_id ? "Contact" : "Account"}</strong>
                        </div>
                        {person.contact_id ? (
                            <>
                                {person.contact_details?.job_title && <span>{person.contact_details?.job_title}</span>}
                                <div>
                                    <Icon className={STAGE_ICONS[person.contact_details?.pipeline_stage]} style={{ marginRight: 6 }} />
                                    <strong>{StringUtils.capitalize(person.contact_details?.pipeline_stage || "") || ""}</strong>
                                </div>
                            </>
                        ) : (person.account_details?.customer && (
                            <div>
                                <Icon className='fa-solid fa-user-crown' style={{ marginRight: 6 }} />
                                <strong>Customer</strong>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 15, marginTop: 15, marginLeft: 10 }}>
                <Icon className='fa-solid fa-phone OfficeView__icons' size={'large'} disabled={isDialDisabled} primary />
                <Icon className='fa-solid fa-envelope OfficeView__icons' size={'large'} />
                <Icon className='fa-solid fa-clipboard-check OfficeView__icons' size={'large'} />
                <Icon className='fa-solid fa-calendar-day OfficeView__icons' size={'large'} />
                <Icon className='fa-solid fa-fire-flame OfficeView__icons' size={'large'} />
            </div>
        </div>
    )
}

export default OfficeViewMiddleHeader;