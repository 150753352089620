import React from 'react';
import './index.scss';
import ButtonLink from 'dyl-components/atoms/ButtonLink';
import { Icon } from 'semantic-ui-react';

const DialButton = ({ symbol, subtext = "", onClick = () => {}, call = false, disabled = false }) => {
    return (
        <ButtonLink disabled={disabled} onClick={() => onClick(symbol)}>
            <div 
                className={
                    `${call ? 
                        ("OfficeViewDialPad__callButton") : 
                        (`OfficeViewDialPad__dialButton ${!disabled && "OfficeViewDialPad__dialButton--hoverEffect"}`)
                    }`
                }
            >
                {call ? (
                    <Icon className='fa-solid fa-phone' size='big' />
                ) : (
                    <>
                        <span className='OfficeViewDialPad__symbol'>{symbol}</span>
                        <span>{subtext}</span>
                    </>
                )}
            </div>
       </ButtonLink>
    )
};

export default DialButton;