import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Form, Icon, Input, Label } from 'semantic-ui-react';
import { VALIDATION_PATTERNS } from 'dyl-components';

import './searchbar.scss';
/* 
This is the bases work for a reusable SesarchBar.

Ideally moving forward,  we move towards using routes and React Hooks instead of class constrcutors 
@addClassName: pass additional classes (if needed to be styled for a particular page)
@isSearching: this is the parents state for managing the search term
@searchFcn: this is the actually function or dispatch used for the particular search
@cancelFcn: if the parean needs something to run when clearing searches 
@minSearchValue:  default to 2 can be modified depending on circumstances
@placeholder:  Custom text
@onChange:  this function is for the old school class components that do search value updates via the parent's functions/state 

*/
const SearchBar = forwardRef(({
    isSearching = null,
    search = '',
    searchFcn = null,
    cancelFcn = null,
    minSearchValue = 2,
    placeholder = 'Please enter search term',
    onChange = null,
    addClassNames = null,
    validationPattern = VALIDATION_PATTERNS.alphanumeric_search,
    dropdown
}, ref) => {

    const buttonRef = useRef(null);

    const [searchValue, setSearchValue] = useState(search);
    const [hasMinValue, setHasMinValue] = useState(false);
    const [isBarSearching, setIsBarSearching] = useState(true);
    const [isBadString, setIsBadString] = useState(false)
    const isSearched = search !== '';

    const testStringFailed = (value) => validationPattern.test(value);

    const isFcn = (test) => (typeof test === 'function');

    const cancelAction = () => {
        setSearchValue('');
        if (isFcn(cancelFcn)) {
            cancelFcn(searchValue)
        } else {
            console.info(`|+|+ if cleanup is needed Please pass cancel search function `)
        }
    };
    const searchAction = () => {
        if (isSearching === null) {
            setIsBarSearching(true);
            setTimeout(() => {
                setIsBarSearching(false)
            }, 2000);
        }

        if (hasMinValue) {
            if (isFcn(cancelFcn)) {
                searchFcn(searchValue);
            } else {
                console.info(`|+|+ We need a search function passed to this component `)
            }
        }
    }

    const checkButton = (search === searchValue || isBadString); // trigger for showing enter or cancel. 
    const buttonAction = {
        pointing: 'left',
        className: 'search',
        circular: true,
        basic: false,
        icon: checkButton ? 'cancel' : 'level up',
        onClick: checkButton ? cancelAction : searchAction,
        type: "button",
        ref: buttonRef
    };

    useEffect(() => {
        setSearchValue(search);
    }, [search]);

    useEffect(() => {
        setHasMinValue(searchValue.length >= minSearchValue);
        const test = testStringFailed(searchValue);
        setIsBadString(test)
    }, [searchValue, hasMinValue], isSearched, search);

    useEffect(() => {
        setIsBarSearching(isSearching);
    }, [isSearching]);

    return (
        <Form.Field className={`SearchBar__SearchInput ${addClassNames} ${isBadString ? "SearchBar__errorInput" : ""}`} error={isBadString}>
            <Input
                icon='search'
                iconPosition='left'
                placeholder={placeholder}
                onChange={(_, { value }) => {
                    setSearchValue(value);
                    if (typeof onChange === "function") {
                        onChange(_, { value })
                    }
                }}
                value={searchValue}
                onKeyPress={(event) => {
                    if (event.charCode === 13 && !isBadString) {
                        event.preventDefault();
                        searchAction()
                    }
                    if (event.charCode === 13 && searchValue === '') {
                        cancelAction()
                    }
                }}
                loading={isBarSearching}
                disabled={isBarSearching}
                labelPosition={isSearched ? 'right' : null}
                action={
                    hasMinValue ? buttonAction : !isSearching && dropdown && <Icon className='fas fa-caret-down SearchBar__dropdown-icon' />
                }
                ref={ref}
            />
            {isBadString && (
                <Label basic pointing className='SearchBar__redLabel'>
                    Special characters cannot be used in this search
                </Label>
            )}
        </Form.Field>
    );
})


export default SearchBar;
