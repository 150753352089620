import { OfficeViewAdditionalLines, OfficeViewMiddleHeader, OfficeViewMiddleNumbers, OfficeViewSearchResults, SearchableOptions } from 'dyl-components';
import React from 'react';
import './index.scss';
import { Header } from 'semantic-ui-react';

const LIMIT = 15;

const OfficeViewPhoneMiddle = ({
    onNumberClick,
    search,
    isSearchResultsVisible,
    page,
    searchFunction,
    onPersonSelect,
    onChangeSearch,
    onPageChange,
    cancelFunction,
    isSearchingContacts,
    contacts_searched_count,
    contact_lookup,
    onRemovePerson,
    isDialDisabled
}) => {
    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <div className='OfficeView__searchContainer'>
                <SearchableOptions
                    loading={isSearchingContacts}
                    search={search}
                    searchFunction={searchFunction}
                    cancelSearchFunction={cancelFunction}
                    searchPlaceholder={'Search by name or number'}
                    onChangeSearch={onChangeSearch}
                    isSearchResultsVisible={isSearchResultsVisible}
                    renderSearchResults={(
                        <OfficeViewSearchResults onPersonSelect={onPersonSelect} />
                    )}
                    isPaginationVisible={true}
                    paginationProps={{
                        boundaryRange: 0,
                        activePage: page,
                        ellipsisItem: null,
                        siblingRange: 2,
                        totalPages: Math.ceil(contacts_searched_count / LIMIT),
                        onPageChange
                    }}
                    floatingResults
                />
            </div>
            <div className='OfficeView__midContainer'>
                {contact_lookup ? (
                    <>
                        <OfficeViewMiddleHeader person={contact_lookup} onRemovePerson={onRemovePerson} isDialDisabled={isDialDisabled} />
                        <OfficeViewMiddleNumbers person={contact_lookup} onNumberClick={onNumberClick} />
                    </>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center'}}>
                        <Header as={"h2"}>No Active Call</Header>
                    </div>
                )}
            </div>
            <OfficeViewAdditionalLines />
        </div>

    )
}

export default OfficeViewPhoneMiddle;