import React from 'react';

const OfficeViewContent = ({ pages, selectedTab, ...props }) => {
    const Component = pages.find(({ id }) => id === selectedTab)?.component || null;

    if (typeof Component === 'function') {
        return (
            <div style={{ height: '100%' }}>
                <Component {...props} />
            </div>
        );
    }

    return <></>
}

export default OfficeViewContent;
