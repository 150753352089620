import React from 'react';
import './index.scss';
import { Icon } from 'semantic-ui-react';

const OfficeViewHeader = ({ state = 'idle' }) => {
    return (
        <div className={`OfficeViewHeader ${state ? `OfficeViewHeader--${state}` : ""}`}>
            <div style={{flex: 1}}>
                {/* <div className='OfficeViewHeader__ball' /> //TODO: CER-4483: Figure out */}
            </div>
            <Icon className={"fa-solid fa-arrow-up-right-from-square OfficeViewHeader__icon"} />
        </div>
    )
};

export default OfficeViewHeader;