import React, { useState } from 'react';

import './index.scss';
import { ButtonLink, Icon } from 'dyl-components';

const OfficeViewAdditionalLines = ({ height = 300, lines = [] }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div style={{ height: 30, width: "100%", position: 'relative', overflow: "visible" }}>
            <div 
                className={`OfficeView__additionalLines ${isExpanded ? "OfficeView__additionalLines--expanded" : ""}`}
                {...(isExpanded && { style: { height } })}
            >
                <span style={{ flex: 1 }}>Additional Lines</span>
                <ButtonLink onClick={ () => setIsExpanded((prevValue) => !prevValue) }>
                    <Icon className={`fa-solid fa-circle-chevron-${isExpanded ? "down" : "up"}`} />
                </ButtonLink>
                
                {isExpanded && (
                    <div>
                        {lines.map((line) => (<span>{ line }</span>))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default OfficeViewAdditionalLines;

